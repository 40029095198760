/* You can add global styles to this file, and also import other style files */
@import "./scss/plugins/owl-carousel/owl.carousel.scss";
@import "./scss/style.scss";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
body {
  visibility: visible;
  opacity: 1;
}
