/* Modals */

.modal-body {
  padding: 0;

  .close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    z-index: 9999;
    margin-bottom: 3rem;
  }
}

.close {
  font-weight: normal;
  font-size: 1.8rem;
  color: $body-text;
  opacity: 1;
  transition: all 0.3s;

  &:hover,
  &:focus {
    color: $primary-color;
  }
}

.modal-close {
  position: absolute;
  font: normal normal 2.6rem/1 "buy1way";
  color: #666;
  font-size: 1.8rem;
  top: 1.5rem;
  right: 2rem;
  display: block;
  width: 2rem;
  height: 2rem;
}
