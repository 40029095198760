/* Category */
.toolbox {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 2rem;
  @include clearfix();

  .select-custom {
    margin-bottom: 0;

    &::after {
      right: 0.9rem;
      font-size: 1rem;
    }

    .form-control {
      font-size: 1.3rem;
      padding-right: 2rem;
      max-width: 140px;
      min-width: 140px;
      background-color: transparent;
      border-color: #e4e4e4;
    }
  }

  label {
    font-weight: 400;
    color: $dark-text;
    font-size: 1.2rem;
    letter-spacing: -0.01em;
    margin: 0;
  }

  .form-control {
    color: $light-text;
    font-weight: 300;
    font-size: 1.2rem;
    padding: 0.45rem 1rem;
    height: 30px;
    margin-bottom: 0;

    &:not(:focus) {
      border-color: #d7d7d7;
    }
  }

  select.form-control:not([size]):not([multiple]) {
    height: 30px;
  }
}

.toolbox-left,
.toolbox-right {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.toolbox-center {
  margin-top: 1rem;
  flex: 0 1 auto;
}

.toolbox-right {
  margin-top: 1rem;
  flex-direction: column;
}

@include mq("768px") {
  .toolbox-right {
    margin-left: auto;
  }
}

.toolbox-info {
  color: #ccc;

  span {
    color: $dark-text;
  }
}

.toolbox-sort {
  display: flex;
  align-items: center;

  label {
    margin-right: 1.6rem;
  }
}

.toolbox-layout {
  margin-top: 1rem;
}

.btn-layout {
  display: inline-flex;
  padding: 0.5rem;

  & + .btn-layout {
    margin-left: 0.5rem;
  }

  svg {
    fill: #dadada;
    transition: fill 0.35s;
  }

  &:hover,
  &:focus,
  &.active {
    svg {
      fill: #333;
    }
  }
}

.products {
  > .row > [class*="col-"] {
    // display: flex;
    align-items: stretch;
    align-content: stretch;
  }

  .product {
    width: 100%;
  }
}

.product {
  overflow: hidden;
}

.shop .product-media img {
  filter: brightness(0.94);
}

.carousel-equal-height {
  .owl-stage,
  .owl-item {
    display: flex;
    align-items: stretch;
  }

  .product {
    width: 100%;
  }

  buy1way-product-twelve {
    display: flex;
  }
}

.owl-carousel {
  .entry {
    margin-bottom: 0;
  }
}

.load-more-container {
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.btn.btn-load-more {
  text-transform: uppercase;
  min-width: 190px;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;

  i {
    margin: 0 !important;
  }
}

.sidebar-shop {
  .widget {
    color: $dark-text;
    margin: 0 0 2rem;
    border-bottom: 0.1rem solid #ebebeb;

    &:last-child {
      border-bottom: none;
    }
    &.widget-collapsible {
      overflow: hidden;
    }
  }

  .widget-title {
    color: $dark-text;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1.15;
    letter-spacing: -0.01em;
    margin-bottom: 0.7rem;
  }

  .custom-control {
    padding-left: 2.7rem;
    margin: 0;
  }

  .custom-control-label {
    font-weight: 400;
    color: $dark-text;
    cursor: pointer;

    &::before {
      border-radius: 0.2rem;
    }
  }

  &.sidebar-filter .widget {
    padding-right: 0;
  }

  &::-webkit-scrollbar {
    width: 7px;
    height: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ebebeb;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-image: 10px;
    margin: 8px;
    width: 100%;
  }
}

.widget-clean {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 2.3rem;

  label {
    color: inherit;
    font-weight: 400;
    font-size: 1.4rem;
    letter-spacing: -0.01em;
    margin: 0;
  }

  a {
    display: inline-block;
    font-weight: 400;
    font-size: 1.2rem;
    margin-left: auto;
  }
}

.widget-collapsible {
  .widget-title {
    a {
      position: relative;
      display: block;
      color: inherit;
      text-decoration: none;
      padding-right: 3rem;

      &:after {
        color: $dark-text;
        content: "\f110";
        display: block;
        font-family: "molla";
        font-size: 1.4rem;
        line-height: 1;
        position: absolute;
        top: 50%;
        right: 1.6rem;
        margin-top: -0.7rem;
      }

      &.collapsed {
        &:after {
          content: "\e802";
        }
      }

      &:focus {
        outline: none !important;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .widget-body {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
}

.filter-item {
  position: relative;
  margin-bottom: 0.3rem;

  a {
    color: $dark-text;

    &.active {
      color: $primary-color;
    }
  }
}

.filter-items-count {
  .filter-item {
    padding-right: 4rem;
  }

  .item-count {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 1rem;
    top: 50%;
    min-width: 2.5rem;
    height: 2rem;
    margin-top: -1rem;
    font-weight: 300;
    font-size: 1.3rem;
    line-height: 1;
    border-radius: 0.6rem;
    color: #777;
    background-color: #f8f8f8;
  }
}

.sidebar-toggler {
  display: inline-flex;
  align-items: center;
  color: $dark-text;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  margin-right: 3rem;

  i {
    font-size: 1.6rem;
    margin-right: 0.9rem;
  }

  &:hover,
  &:focus {
    color: $primary-color;
  }
}

.sidebar-fixed-toggler {
  display: block;
  position: fixed;
  left: 0;
  top: 49vh;
  width: 30px;
  height: 30px;
  z-index: 1000;
  color: #fff;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: transform 0.35s;
  i {
    font-size: 1.8rem;
    line-height: 1.6;
  }

  &.right {
    left: unset;
    right: 0;
    border-radius: 3px 0 0 3px;
  }
}

.sidebar-filter-active .sidebar-fixed-toggler {
  -webkit-transform: translateX(270px);
  transform: translateX(270px);
  &.right {
    -webkit-transform: translateX(-270px);
    transform: translateX(-270px);
  }
}

.sub-filter-items {
  padding-left: 2.7rem;
}

.filter-colors {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 0.7rem;
  padding-bottom: 0.5rem;

  a {
    position: relative;
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    border: 0.2rem solid #fff;
    margin: 0 0.2rem 0.3rem;
    transition: box-shadow 0.35s ease;

    &:after {
      color: #fff;
      font-family: "molla";
      content: "\ec2d";
      font-size: 1rem;
      line-height: 1;
      position: absolute;
      left: 50%;
      top: 50%;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.01s;
      margin-top: -0.4rem;
      margin-left: -0.5rem;
    }

    &.selected,
    &:hover,
    &:focus {
      box-shadow: 0 0 0 0.1rem #cccccc;
    }

    &.selected {
      &:after {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

@include only_for_edge() {
  .filter-colors a:after {
    margin-top: -0.5rem;
  }
}

.filter-price {
  padding-top: 0.2rem;
  padding-bottom: 3rem;
}

.filter-price-text {
  margin-bottom: 1.5em;
  letter-spacing: -0.01em;
}

#filter-price-range {
  color: $primary-color;
}

@include mq("sm") {
  .toolbox-right {
    flex-direction: row;
  }

  .toolbox-layout {
    margin-top: 0;
    margin-right: -0.5rem;
    margin-left: 1rem;
  }
}

@include mq("md") {
  .toolbox {
    flex-direction: row;
  }

  .toolbox-center {
    margin-top: 0;
  }

  .toolbox-right {
    margin-top: 0;
    justify-content: flex-end;
  }
}
