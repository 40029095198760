/* Spacing */
@for $i from 0 through 15 {
    .mt-#{$i} {
        margin-top: #{$i}rem !important;
    }

    .mb-#{$i} {
        margin-bottom: #{$i}rem !important;
    }

    .pt-#{$i} {
        padding-top: #{$i}rem !important;
    }

    .pb-#{$i} {
        padding-bottom: #{$i}rem !important;
    }
}

@include mq('md') {
	@for $i from 0 through 15 {
	    .mt-md-#{$i} {
	        margin-top: #{$i}rem !important;
	    }
	}

	@for $i from 0 through 15 {
	    .mb-md-#{$i} {
	        margin-bottom: #{$i}rem !important;
	    }
	}

	@for $i from 0 through 15 {
	    .pt-md-#{$i} {
	        padding-top: #{$i}rem !important;
	    }
	}

	@for $i from 0 through 15 {
	    .pb-md-#{$i} {
	        padding-bottom: #{$i}rem !important;
	    }
	}
}

@include mq('lg') {
	@for $i from 0 through 15 {
	    .mt-lg-#{$i} {
	        margin-top: #{$i}rem !important;
	    }
	}

	@for $i from 0 through 15 {
	    .mb-lg-#{$i} {
	        margin-bottom: #{$i}rem !important;
	    }
	}

	@for $i from 0 through 15 {
	    .pt-lg-#{$i} {
	        padding-top: #{$i}rem !important;
	    }
	}

	@for $i from 0 through 15 {
	    .pb-lg-#{$i} {
	        padding-bottom: #{$i}rem !important;
	    }
	}
}

@include mq('xl') {
	@for $i from 0 through 15 {
	    .mt-xl-#{$i} {
	        margin-top: #{$i}rem !important;
	    }
	}

	@for $i from 0 through 15 {
	    .mb-xl-#{$i} {
	        margin-bottom: #{$i}rem !important;
	    }
	}
}

@include mq('xxl') {
	@for $i from 0 through 15 {
	    .mt-xxl-#{$i} {
	        margin-top: #{$i}rem !important;
	    }
	}

	@for $i from 0 through 15 {
	    .mb-xxl-#{$i} {
	        margin-bottom: #{$i}rem !important;
	    }
	}
}