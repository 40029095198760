.header,
.footer,
.breadcrumb-nav-fluid {
  .container {
    width: 1800px;
    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.intro-slider-container,
.intro-slider .slide-image {
  min-height: 494.5px;
  &:before {
    @include aspect-ratio(1180, 500);
  }

  &:before {
    @include mq("xl", "max") {
      padding-top: 49.45rem;
    }
  }

  img {
    min-height: 49.45rem;
    object-fit: cover;
  }
}

.container,
.container-fluid {
  width: 1800px;
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.home-page .owl-carousel.brands-slider {
  .owl-prev,
  .owl-next {
    width: 42px;
    height: 42px;
    border: none;
    margin-top: -21px;
    font-size: 30px;
    color: #ccc;
  }
}

.font-weight-bolder {
  font-weight: 600 !important;
}

.trending-products {
  padding: 4.5rem 5rem 5rem;
}

.mobile-menu-wrapper .nav.nav-pills-mobile .nav-link {
  &::before {
    content: none;
  }
}

.mobile-menu-wrapper .nav-item.show .nav-link {
  color: $primary-color !important;
}

.heading.heading-center {
  margin-bottom: 2.5rem;
  .title {
    margin: 5rem 0 0.2rem;
    font-size: 2.8rem;
    color: secondary-color-dark;
  }
}

.cat-block {
  margin: 0 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  transition: box-shadow 0.35s, border-color 0.35s;
  @media screen and (max-width: 768px) {
    margin: 0;
    padding: 0 20px;
  }

  figure {
    background-color: #f2f2f2;
    position: relative;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 0;
  }

  &:hover {
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.15);
  }
}

.cat-content {
  padding: 0.2rem 1rem;
  width: 400px;
}

.cat-title {
  margin-bottom: 2rem;
  font-size: 1.6rem;
  letter-spacing: -0.01em;
  color: secondary-color-dark;
  text-overflow: ellipsis;
  &:hover {
    color: $primary-color;
  }
  a {
    color: inherit;
  }
}

.cat-list {
  margin-bottom: 0.6rem;
  font-size: 1.35rem;
  line-height: 1.6;
  color: $body-text;
  a {
    display: block;
    position: relative;
    padding-left: 0.2rem;
    color: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 5px;
  }
}

.cat-item:hover {
  a {
    color: $primary-color;
    font-size: 1.5rem;
  }
}

.title {
  font-weight: 600;
  font-size: 2rem;
  letter-spacing: -0.025em;
}

.title-desc {
  letter-spacing: 0;
}

.heading {
  margin-bottom: 4.4rem;
}

.heading .title {
  margin-bottom: 1rem;
}

.intro-slide .intro-content {
  padding-bottom: 1rem;
}

.home-page .intro-slider .owl-dots {
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}

.intro-content {
  bottom: 50%;
  left: 20px;

  .btn {
    color: #333333;
    border-radius: 0.3rem;
    text-transform: uppercase;
    letter-spacing: 0;
  }
}

.intro-subtitle {
  color: #777;
  font-weight: 300;
  margin-bottom: 0.8rem;
  letter-spacing: -0.01em;
}

.intro-title {
  color: #e9ddb4;
  font-weight: 600;
  font-size: 2.8rem;
  letter-spacing: -0.025em;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  span {
    font-weight: 300;
  }
}

.intro-text {
  color: #f1f0eb;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.3;
  letter-spacing: -0.025em;
  margin-bottom: 1.6rem;
}

.intro-price {
  color: #333333;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 1.2;
  letter-spacing: -0.025em;
  margin-top: -0.2rem;
  margin-bottom: 1.7rem;

  sup {
    top: -0.8em;
    font-weight: 400;
    font-size: 1.6rem;
    letter-spacing: -0.03em;
  }
}

.intro-banner {
  min-height: 494.5px;
}

.home-page {
  .nav .bg-white {
    background-color: transparent !important;
  }

  .brands-slider .brand {
    height: 10rem;
  }

  .banner {
    overflow: hidden;
    background-color: #f1f1f1;
  }

  .banner:not(.intro-banner),
  .banner:not(.intro-banner) img {
    max-height: 40rem;
    min-height: 20rem;
    border-radius: 0.2rem;
  }

  .banner-content {
    padding-top: 0;
    left: 2rem;

    .price {
      padding-right: 1.3rem;
      line-height: 2.4em;
      sup {
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: -0.01em;
        top: -0.4em;
      }
      span {
        margin-left: 0.3rem;
        font-size: 2.4rem;
        letter-spacing: -0.01em;
      }
      strong {
        font-weight: 600;
      }
    }
  }

  .banner-subtitle {
    font-weight: 300;
    font-size: 1.4rem;
    letter-spacing: -0.01em;
    margin-bottom: 1.1rem;

    &.text-white {
      color: #ebebeb !important;
    }
  }

  .banner-title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 1.3;
    letter-spacing: -0.025em;
    margin-bottom: 1.4rem;

    span {
      font-weight: 300;
    }
  }

  .banner-link {
    width: auto;
    min-width: 150px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.4;
    letter-spacing: -0.01em;
    border-radius: 0.3rem;
    padding: 0.7rem 1rem 0.6rem;
    background-color: rgba(255, 255, 255, 0.2);
    transition: all 0.35s;

    i {
      display: inline-block;
      width: 0;
      line-height: 1;
      overflow: hidden;
      margin-left: 0;
      margin-bottom: -1px;
      transition: width 0.35s, margin 0.35s, color 0s;
    }

    &.banner-link-dark:not(:hover):not(:focus) {
      background-color: rgba(51, 51, 51, 0.2);
    }

    &:hover,
    &:focus {
      color: #333;
      text-decoration: none !important;
      background-color: $primary-color;

      i {
        width: auto;
        margin-left: 0.7rem;
      }
    }
  }

  .banner-text {
    a {
      color: inherit;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  .entry-media {
    a {
      display: block;

      span {
        display: block !important;
      }
    }
  }

  .owl-carousel .entry-media::before {
    @include aspect-ratio(280, 170);
  }

  .product-media {
    &:before {
      @include aspect-ratio(218, 192);
    }
  }

  .nav.nav-pills .nav-item.show .nav-link,
  .nav.nav-pills .nav-item .nav-link.active {
    color: #666;
  }

  .nav.nav-pills {
    .nav-link {
      color: #666;
      font-weight: 400;
      font-size: 1.6rem;
      text-transform: capitalize;
      padding-bottom: 1.5rem;
    }
  }

  .product.product-sm {
    align-items: center;
    margin-bottom: 2rem !important;

    .product-media {
      flex: 0 0 70px;
      max-width: 70px;
    }
    .product-title {
      line-height: 1.5em;
    }
    .product-body {
      padding: 0 0 0rem 2rem;
    }
  }
  .product-label {
    border-radius: 0.2rem;
  }

  .product {
    border-radius: 0.2rem;
    overflow: hidden;
  }

  .product-action-vertical {
    transform: translateY(0);
  }

  .trending-products .product:hover .product-action {
    margin-bottom: 1rem;
  }

  .btn-product-icon {
    border-radius: 50%;
    background-color: #fff;
    border: 0.1rem solid $primary-color;

    span {
      top: -1px;
      bottom: -1px;
    }

    &:hover,
    &:focus {
      background-color: $primary-color;

      span {
        background-color: $primary-color;
      }
    }
  }

  .btn-wishlist {
    &:hover,
    &:focus {
      &:before {
        content: "\f233";
      }
    }
  }

  .product-action {
    left: 2rem;
    right: 2rem;
    transform: translateY(0);
  }

  .btn-product {
    padding-top: 1.05rem;
    padding-bottom: 1.05rem;
    color: #fff;
    text-transform: uppercase;
    border-radius: 0.2rem;
    border: 0.1rem solid $primary-color;
    background-color: $primary-color;

    &:before {
      margin-right: 0.6rem;
    }

    span {
      color: #fff;
      border: none;
    }

    &:before {
      display: inline-block;
      margin-top: -0.1rem;
    }

    &:hover,
    &:focus {
      color: $primary-color;
      background-color: #333;
      border-color: #333;

      span {
        color: $primary-color;
        box-shadow: none;
      }
    }
  }

  .product-body {
    padding-bottom: 1.8rem;
  }

  .product-cat {
    color: #ccc;
    text-transform: uppercase;
    margin-bottom: 1.2rem;
  }

  .product-price {
    font-size: 1.4rem;
    margin-bottom: 1.1rem;
  }

  .old-price {
    text-decoration: none;
  }

  .product-title {
    color: #666;
    font-size: 1.4rem;
    margin-bottom: 0.3rem;
  }

  .ratings-text {
    margin-left: 0.5rem;
  }

  .product-countdown {
    border-radius: 0.2rem;
    background-color: $primary-color;
    left: 2rem;
    right: 2rem;
    bottom: 0;
    padding: 0.7rem 1rem 0.6rem;

    .countdown-section {
      background-color: transparent;
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      position: relative;
      margin-bottom: 1.1rem;

      &:not(:last-child):after {
        font-weight: 400;
        font-size: 1.6rem;
        margin-left: -0.1rem;
      }
    }

    .countdown-amount {
      color: #fff;
      font-weight: 500;
      font-size: 1.6rem;
      letter-spacing: -0.01em;
      margin-bottom: 0;
    }

    .countdown-period {
      color: #fff;
      display: block;
      position: absolute;
      letter-spacing: 0;
      left: 0;
      right: 0;
      bottom: -1.1rem;
    }
  }

  .product-countdown .countdown-show4 .countdown-section {
    width: 25%;
  }

  .owl-prev,
  .owl-next {
    &::before {
      height: auto;
      top: 6%;
      bottom: 7%;
      width: 7px;
    }
  }
}

.product.product-7 .product-media img {
  height: 100%;
  object-fit: cover;
}

.banner-content-stretch {
  .banner-content {
    top: 10rem;
    bottom: 4rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .banner-link {
      background-color: rgba(153, 153, 153, 0.2);
      color: #333;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      &:hover,
      &:focus {
        background-color: $primary-color;
      }
    }
  }
  a::after {
    display: none;
  }
}

.widget-banner {
  .banner-content {
    left: 3rem;
  }

  .banner-content-right {
    right: 3rem;
  }

  .banner-content-top {
    top: 3.5rem;
    transform: translateY(0);
  }
}

.trending-products {
  padding: 4.5rem 5rem 5rem;

  .owl-nav .owl-prev {
    left: -40px;
  }

  .owl-nav .owl-next {
    right: -40px;
  }

  .owl-dots {
    margin-top: 2.5rem;
  }
  .product-media {
    padding: 2rem 2rem;
    background-color: #fff;
  }
  .product-body {
    padding-top: 5px;
    padding-bottom: 2.8rem;
  }
}

.home-page .carousel-with-shadow.owl-full .owl-nav [class*="owl-"] {
  margin-top: 6px;
}
.owl-full.carousel-with-shadow .owl-nav [class*="owl-"] {
  height: calc(100% - 40px);
}

.electronics,
.furniture,
.clothing,
.cooking {
  .product-media {
    background-color: #fff;
    padding: 0.5rem;
  }
}

.owl-full {
  .owl-nav {
    [class*="owl-"] {
      color: #666;
    }
  }
}

.widget-deals {
  .product-media {
    img {
      width: 100%;
      min-height: unset;
    }
  }

  .product-label {
    top: 1rem;
  }
}

.widget-posts {
  padding-top: 0.5rem;
  .sidebar-home & .widget-title {
    margin-bottom: 3rem;
  }
}

.cat-banner-list {
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center center;
  padding-top: 3rem;
  padding-bottom: 3rem;

  h2 {
    font-weight: 600;
    font-size: 2rem;
    letter-spacing: -0.025em;
    margin-bottom: 2rem;
  }

  ul {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.5;

    li:not(:last-child) {
      margin-bottom: 0.8rem;
    }

    li a {
      &:before {
        content: "•";
        margin-right: 0.8rem;
      }
    }
  }

  a {
    color: #333;

    &:hover,
    &:focus {
      color: $primary-color;
    }
  }

  .list-all-link {
    a {
      color: $primary-color;

      &:hover,
      &:focus {
        box-shadow: 0 1px 0 $primary-color;
      }
    }
  }
}

.banner-list-content {
  padding-left: 4rem;
  padding-top: 1rem;
}

.cat-banner {
  max-width: 480px;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;

  .banner {
    margin-bottom: 0;
  }

  .banner-content {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    top: 4.5rem;
    left: 4.5rem;
    bottom: 5rem;
    transform: translateY(0);
  }

  .banner-title {
    flex-grow: 1;
  }

  .banner-link {
    align-self: flex-start;
  }
}

.sidebar-home {
  margin-top: 0;
  margin-bottom: 5rem;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;

  &::-webkit-scrollbar {
    height: 10px;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ebebeb;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
    margin: 8px;
    width: 100%;
  }

  .widget {
    margin-bottom: 3rem;
  }

  .widget-title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 1.25;
    letter-spacing: -0.01em;
    margin-bottom: 2rem;
    padding-bottom: 1.4rem;
    border-bottom: 0.1rem solid #ebebeb;

    span {
      display: inline-block;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        bottom: -1.5rem;
        height: 0.1rem;
        background-color: $primary-color;
      }
    }
  }
}

.home-page .widget-products {
  .products {
    padding: 0;
    margin: 0;
  }

  .product:last-child {
    margin-bottom: 0;
  }

  .product-media {
    border: 0.1rem solid #f1f1f1;
  }

  .product-title {
    color: #666;
  }

  .product-sm .product-price {
    font-size: 1.4rem;
  }
}

.widget-deals {
  .product {
    border-bottom: 0.1rem solid #ebebeb;
    padding-bottom: 7rem;
    margin-bottom: 2rem;

    &:hover {
      .product-countdown {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .product-action {
    left: 3rem;
    right: 3rem;
  }

  .product-countdown {
    left: 3rem;
    right: 3rem;
    bottom: -4rem;
  }

  .ratings-container:last-child {
    margin-bottom: 0.5rem;
  }
}

.widget-posts {
  overflow: hidden;

  .entry {
    color: #777;
    font-size: 1.4rem;
    line-height: 1.7;
    margin-bottom: 0;
  }

  .entry-media {
    margin-bottom: 1.5rem;
    border-radius: 0.2rem;
    overflow: hidden;
  }

  .entry-body {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .entry-meta {
    color: #ccc;
    margin-bottom: 0.3rem;
  }

  .entry-title {
    font-weight: 600;
    font-size: 1.6rem;
    margin-bottom: 0.7rem;
  }

  .entry-content p {
    margin-bottom: 0.6rem;
  }

  .read-more {
    font-size: 1.4rem;
    text-transform: capitalize;
  }

  .owl-nav.disabled + .owl-dots {
    margin-top: 2.5rem;
  }
}

.icon-boxes-container {
  background-color: #fafafa;
}

.icon-box-side {
  justify-content: center;
  margin-left: -1rem;

  .icon-box-title {
    font-weight: 600;
    margin-bottom: 0.4rem;
  }
  p {
    font-size: 1.6rem;
    letter-spacing: -0.01em;
  }
  .icon-box-icon {
    font-size: 4rem;
    margin-bottom: 0.5rem;
  }
}

.home-page {
  .cta-horizontal-box {
    padding-top: 3.8rem;
    padding-bottom: 3.6rem;
    form {
      display: flex;
      justify-content: flex-end;
    }
    .input-group {
      max-width: 670px;
    }
    .cta-desc {
      line-height: 1.3em;
    }
  }

  .cta-horizontal .form-control {
    margin-right: 1rem;
  }

  .cta-title {
    font-weight: 600;
    letter-spacing: -0.025em;
  }

  .cta {
    .form-control {
      border-radius: 0.3rem;
    }

    .input-group > .custom-select:not(:last-child),
    .input-group > .form-control:not(:last-child) {
      border-radius: 0.3rem;
      border: none;
    }

    .btn {
      min-width: 170px;
      text-transform: uppercase;
      color: #fff;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 0.3rem !important;

      &:hover,
      &:focus {
        color: #fff;
        border-color: $primary-color;
        background-color: $primary-color;
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .intro-title {
    font-size: 2.4rem;
    margin-bottom: 1rem;
  }
}

@include mq("sm") {
  .cat-banner-list {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@include mq("md") {
  .home-page .intro-slider .owl-dots {
    left: 90px;
    bottom: 30px;
  }

  .intro-content {
    left: 60px;
  }

  .intro-title {
    font-size: 3.4rem;
  }

  .intro-price {
    font-size: 3.8rem;
  }

  .home-banner .banner-content {
    left: 5rem;
  }
}

@include mq("lg") {
  .home-page .heading .title {
    margin-bottom: 0;
  }

  .header-14 .sticky-header.fixed {
    background-color: #333;
  }
}

@include mq("xl") {
  .owl-carousel.brands-carousel {
    margin-left: 6rem;
    margin-right: 6rem;
    width: calc(100% - 12rem);
  }

  .cat-banner {
    max-width: none;
  }

  .home-page .intro-slider .owl-dots {
    left: 120px;
    bottom: 40px;
  }

  .intro-content {
    left: 90px;
  }

  .intro-subtitle {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  .intro-title {
    font-size: 3.6rem;
    margin-bottom: 0.9rem;
  }

  .intro-text {
    font-size: 2rem;
    margin-bottom: 3rem;
  }

  .intro-price {
    font-size: 4rem;
    margin-bottom: 1.5rem;

    sup {
      font-size: 2rem;
    }
  }
}

@include mq("xxl") {
  .container {
    width: 1420px;
    max-width: 1420px;
  }

  .home-page .owl-carousel.intro-slider .owl-dots {
    left: 15.8rem;
    bottom: 70px;
  }

  .intro-content {
    left: 130px;

    .btn {
      min-width: 200px;
      padding-top: 1.15rem;
      padding-bottom: 1.15rem;
    }
  }

  .intro-subtitle {
    font-size: 1.8rem;
    margin-bottom: 1.6rem;
  }

  .intro-title {
    font-size: 4rem;
    margin-bottom: 0.9rem;
  }

  .intro-text {
    font-size: 2.4rem;
    margin-bottom: 4.2rem;
  }

  .intro-price {
    font-size: 5rem;
    margin-bottom: 1.7rem;

    sup {
      top: -0.8em;
      font-size: 2.4rem;
    }
  }
}

@include mq("xlst") {
  .cat-banner-row {
    .col-xxl-4 {
      flex: 0 0 33.65%;
      max-width: 33.65%;
    }

    .col-xxl-8 {
      flex: 0 0 66.35%;
      max-width: 66.35%;
    }

    .product {
      min-height: 400px;
    }
  }
}

@include mq("xl", "max") {
  .sticky-content {
    position: static !important;
  }
  aside.col-xl-3 {
    padding: 0;
  }
  .sidebar-home {
    position: fixed;
    left: -290px;
    top: 0;
    bottom: 0;
    max-width: 290px;
    z-index: 1041;
    background-color: #fff;
    margin-bottom: 0;
    overflow-y: scroll;
    padding: 2rem 1.5rem;
    transition: left 0.35s;

    .sidebar-home-active & {
      left: 0;
    }
  }
  .home-page .sidebar-filter-overlay {
    z-index: 1040;
  }
  .sidebar-home-active {
    .sidebar-fixed-toggler {
      transform: translateX(290px);
    }
    .sidebar-filter-overlay {
      visibility: visible;
      opacity: 1;
    }
  }
}

@include mq("lg", "max") {
  .home-page .cta-horizontal-box {
    .cta-txt {
      text-align: center;
    }
    form {
      justify-content: center;
    }
  }
}

@include mq("sm", "max") {
  .product-countdown {
    display: none;
  }

  .carousel-with-shadow.owl-simple .owl-nav [class*="owl-"] {
    margin-top: 0px;
  }

  .icon-box-side {
    display: block;
    text-align: center;
  }

  .home-page .cta-horizontal .form-control {
    margin-right: 0;
  }
}

@media screen and (max-width: 420px) {
  .trending-products {
    padding: 4.5rem 0.2rem;

    .nav.nav-pills {
      .nav-link {
        font-size: 1.2rem;
        padding: 0.2rem;
      }

      .nav-item + .nav-item {
        margin-left: 0.5rem;
      }
    }
  }

  .banner-group .banner {
    > a {
      min-height: 150px;
      height: 150px;

      img {
        height: 100%;
      }
    }
  }

  .home-page {
    .ratings-text {
      display: none;
    }

    .product-action {
      left: 0.2rem;
      right: 0.2rem;
    }

    .btn-product {
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
    }
  }
}
