/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 5%;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #ffffff;
}
.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
/*Additional properties for button version
   iOS requires the button element instead of an anchor tag.
   If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 0.4rem;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  color: #000000;
  box-shadow: 0px 8px 16px 0px rgba(58, 58, 88, 0.2);
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iIzJmOTZiNCIgY2xhc3M9InctMSBoLTEiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTIuMjUgMTJjMC01LjM4NSA0LjM2NS05Ljc1IDkuNzUtOS43NXM5Ljc1IDQuMzY1IDkuNzUgOS43NS00LjM2NSA5Ljc1LTkuNzUgOS43NVMyLjI1IDE3LjM4NSAyLjI1IDEyem04LjcwNi0xLjQ0MmMxLjE0Ni0uNTczIDIuNDM3LjQ2MyAyLjEyNiAxLjcwNmwtLjcwOSAyLjgzNi4wNDItLjAyYS43NS43NSAwIDAxLjY3IDEuMzRsLS4wNC4wMjJjLTEuMTQ3LjU3My0yLjQzOC0uNDYzLTIuMTI3LTEuNzA2bC43MS0yLjgzNi0uMDQyLjAyYS43NS43NSAwIDExLS42NzEtMS4zNGwuMDQxLS4wMjJ6TTEyIDlhLjc1Ljc1IDAgMTAwLTEuNS43NS43NSAwIDAwMCAxLjV6IiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIC8+Cjwvc3ZnPg==");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iI2Y3MDcwNyIgY2xhc3M9InctMSBoLTEiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTIuMjUgMTJjMC01LjM4NSA0LjM2NS05Ljc1IDkuNzUtOS43NXM5Ljc1IDQuMzY1IDkuNzUgOS43NS00LjM2NSA5Ljc1LTkuNzUgOS43NVMyLjI1IDE3LjM4NSAyLjI1IDEyek0xMiA4LjI1YS43NS43NSAwIDAxLjc1Ljc1djMuNzVhLjc1Ljc1IDAgMDEtMS41IDBWOWEuNzUuNzUgMCAwMS43NS0uNzV6bTAgOC4yNWEuNzUuNzUgMCAxMDAtMS41Ljc1Ljc1IDAgMDAwIDEuNXoiIGNsaXAtcnVsZT0iZXZlbm9kZCIgLz4KPC9zdmc+Cg==");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iIzAwY2M5MiIgY2xhc3M9InctMSBoLTEiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTIuMjUgMTJjMC01LjM4NSA0LjM2NS05Ljc1IDkuNzUtOS43NXM5Ljc1IDQuMzY1IDkuNzUgOS43NS00LjM2NSA5Ljc1LTkuNzUgOS43NVMyLjI1IDE3LjM4NSAyLjI1IDEyem0xMy4zNi0xLjgxNGEuNzUuNzUgMCAxMC0xLjIyLS44NzJsLTMuMjM2IDQuNTNMOS41MyAxMi4yMmEuNzUuNzUgMCAwMC0xLjA2IDEuMDZsMi4yNSAyLjI1YS43NS43NSAwIDAwMS4xNC0uMDk0bDMuNzUtNS4yNXoiIGNsaXAtcnVsZT0iZXZlbm9kZCIgLz4KPC9zdmc+");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iI2Y4OTQwNiIgY2xhc3M9InctMSBoLTEiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTkuNDAxIDMuMDAzYzEuMTU1LTIgNC4wNDMtMiA1LjE5NyAwbDcuMzU1IDEyLjc0OGMxLjE1NCAyLS4yOSA0LjUtMi41OTkgNC41SDQuNjQ1Yy0yLjMwOSAwLTMuNzUyLTIuNS0yLjU5OC00LjVMOS40IDMuMDAzek0xMiA4LjI1YS43NS43NSAwIDAxLjc1Ljc1djMuNzVhLjc1Ljc1IDAgMDEtMS41IDBWOWEuNzUuNzUgMCAwMS43NS0uNzV6bTAgOC4yNWEuNzUuNzUgMCAxMDAtMS41Ljc1Ljc1IDAgMDAwIDEuNXoiIGNsaXAtcnVsZT0iZXZlbm9kZCIgLz4KPC9zdmc+Cg==");
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}
.toast-success {
  background-color: #ffffff;
}
.toast-error {
  background-color: #ffffff;
}
.toast-info {
  background-color: #ffffff;
}
.toast-warning {
  background-color: #ffffff;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #0b0b36;
  opacity: 0.4;
}
/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
