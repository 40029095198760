buy1way-image {
  position: relative;
  display: block;
  background-color: #f4f4f4;

  img.ng-lazyloaded {
    object-fit: contain;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &.h-100 {
    img {
      height: 100%;
    }
  }

  &.w-100 {
    img {
      width: 100%;
    }
  }

  img.ng-lazyloading {
    max-height: 1px;
    height: 0px;
  }

  img.ng-lazyloaded {
    animation-name: fadeIn;
    animation-duration: 0.3s;
  }
}
