/* Lists */

.elements {
  > div {
    padding: 1rem;
    @for $i from 1 through 14 {
    }
    > a {
      display: block;
    }
  }
}

.element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid #fae7bd;
  position: relative;
  transition: all 0.3s;

  &:hover,
  &:focus {
    .element-hover-img {
      visibility: visible;
      opacity: 1;
    }
    .element-img {
      opacity: 0;
    }
    p {
      color: #333;
    }

    box-shadow: 0 5px 20px #ffd992a2;
  }

  p {
    margin-top: -1rem;
    padding-bottom: 1rem;
  }
}

.element-img,
.element-hover-img {
  width: 100px;
  height: 100px;

  background-repeat: no-repeat;
  transition: all 0.3s;
}

.element-type {
  font-size: 1.3rem;
  font-weight: 400;
  font-family: $font-family;
  letter-spacing: 0.01em;
  color: #666;
  text-transform: uppercase;
}

@include mq("374px", "max") {
  .element p {
    font-size: 1.2rem;
  }
}
