/* Contact us */
#map {
	background-color: #ccc;
}

.gm-style .gm-style-iw-c {
    border-radius: 0 !important;
}

#map address {
	color: $body-text;
    font-style: normal;
    font-weight: 400;
    margin: 0 0 1rem;
    font-size: 1.4rem;
    padding: .5rem .75rem .2rem .75rem;
    line-height: 1.5;
    margin: 0;
}

#map .map-container {
	width: 100% !important;
}

.direction-link {
	display: inline-block;
	margin-top: 1rem;
	line-height: 1;
}

.contact-info {
	margin-bottom: 3rem;

	h3 {
		margin-top: .5rem;
		font-weight: 400;
		font-size: 1.8rem;
		letter-spacing: -.025em;
		margin-bottom: 1.5rem;
	}
}

.contact-list {
	max-width: 270px;
	list-style: none;
	padding-left: 0;

	li {
		position: relative;
		padding-left: 3rem;
		margin-bottom: 1.4rem;
	}

	a {
		color: inherit;

		&:hover,
		&:focus {
			color: $primary-color;
		}
	}

	i {
		display: inline-block;
		position: absolute;
		left: 0;
		top: .3rem;
		font-size: 2rem;
		line-height: 1;
		color: $primary-color;
	}
}

.store {
	margin-bottom: 3rem;
	color: #777;

	address,
	div {
		font-weight: 300;
		font-style:normal;
		margin-bottom: 0;
	}

	a:not(.btn) {
		color: inherit;

		&:hover,
		&:focus {
			color: $primary-color;
		}
	}

	.btn.btn-link {
		padding-left: 0;
		padding-right: 0;
		margin-top: 2.1rem;
	}
}

.store-media {
	margin-bottom: 0;
}

.store-title {
	font-weight: 400;
	font-size: 1.6rem;
	letter-spacing: 0;
	margin-bottom: .8rem;
	margin-top: -.2rem;
}

.store-subtitle {
	font-weight: 400;
	font-size: 1.4rem;
	letter-spacing: 0;
	margin-bottom: .4rem;
	margin-top: 3.2rem;
}